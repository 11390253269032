import { Dispatch, useCallback, useReducer } from 'react'

import { FFmpeg } from '@ffmpeg/ffmpeg'
import { fetchFile } from '@ffmpeg/util'
import imageCompression from 'browser-image-compression'
import { UniqueComponentId } from 'primereact/utils'

import { truncateString } from './entries/utils'

interface Stats {
  savedSpace: number // Bytes
  crf?: number // FFMPEG compression aggressiveness (0-13) anything above 15 is generally pretty garbage
  compressedSize?: number // Bytes
  conversionSize?: number // Bytes
  originalSize?: number
}

// export const COMPRESS_VIDEO_SIZE = 50e6 // 50MB
export const MAX_VIDEO_FILE_SIZE = 7.5e6 // 7.5MB
export const MAX_IMAGE_FILE_SIZE = 2.5e6 // 2.5MB

const units = ['Bytes', 'KB', 'MB', 'GB', 'TB']

const ffmpeg = new FFmpeg()

const imageOptions = {
  maxSizeMB: MAX_IMAGE_FILE_SIZE / 1e6,
  // 1080p images should be more than enough
  maxWidthOrHeight: 1920,
  useWebWorker: true,
}

type State = {
  step: string
  progress: number
  file?: File
  error?: string
  stats?: Stats
  ffmpegRunning?: boolean
  thumbnail?: string
}

type Action =
  | { type: 'START'; step: string; ffmpegRunning: boolean }
  | {
      type: 'COMPRESSION'
      step: string
      progress: number
      ffmpegRunning: boolean
      thumbnail?: string
    }
  | {
      type: 'CONVERSION'
      step: string
      progress: number
      ffmpegRunning: boolean
      thumbnail?: string
    }
  | { type: 'COMPLETE'; file: File; stats: Stats }
  | { type: 'ERROR'; error: string; file: File }

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'START':
      return { ...state, step: action.step, progress: 0, error: undefined, ffmpegRunning: true }
    case 'COMPRESSION':
    case 'CONVERSION':
      return {
        ...state,
        step: action.step,
        progress: action.progress,
        ffmpegRunning: action.ffmpegRunning,
        thumbnail: action.thumbnail,
      }
    case 'COMPLETE':
      return { step: 'Complete', progress: 100, file: action.file, stats: action.stats }
    case 'ERROR':
      return { step: 'Error', progress: 0, error: action.error }
    default:
      return state
  }
}

export const optimizeFile = async (
  originalFile: File,
  dispatch?: Dispatch<Action>,
  abortController?: AbortController
) => {
  const fileTempName = UniqueComponentId('temp')
  let file = originalFile
  let stats: Stats = { savedSpace: 0, originalSize: file.size }
  try {
    dispatch?.({ type: 'START', step: 'Initializing', ffmpegRunning: false })

    const updateStats = (updatedFile: File, newInfo?: Record<string, unknown>) => {
      stats = { ...stats, savedSpace: originalFile.size - updatedFile.size, ...newInfo }
      console.log('Video stats', stats)
    }

    if (file.type.includes('image') && file.size >= MAX_IMAGE_FILE_SIZE) {
      dispatch?.({ type: 'START', step: 'Compressing Image', ffmpegRunning: true })
      file = await imageCompression(file, imageOptions)
      updateStats(file)
    }

    if (file.type.includes('video') && file.size >= MAX_VIDEO_FILE_SIZE) {
      if (!window.crossOriginIsolated)
        throw new Error('Cross-origin isolation required for video compression')

      dispatch?.({ type: 'START', step: 'Loading FFmpeg', ffmpegRunning: true })
      if (!ffmpeg.loaded) await ffmpeg.load()

      dispatch?.({ type: 'START', step: 'Writing video to Memory', ffmpegRunning: true })
      await ffmpeg.writeFile(`${fileTempName}.mp4`, await fetchFile(file))

      dispatch?.({
        type: 'COMPRESSION',
        step: 'Preparing to Compress File',
        progress: 0,
        ffmpegRunning: true,
      })

      let step = `Compressing ${truncateString(file.name, 15)}`
      let previewFile = `${URL.createObjectURL(file)}?type=${file.type}`

      // Setup listener after thumbnail has been defined
      ffmpeg.on('progress', ({ progress }) => {
        dispatch?.({
          type: step.includes('compress') ? 'COMPRESSION' : 'CONVERSION',
          step,
          progress,
          ffmpegRunning: true,
          thumbnail: previewFile,
        })
      })

      dispatch?.({
        type: 'COMPRESSION',
        step,
        progress: 0,
        ffmpegRunning: true,
        thumbnail: previewFile,
      })

      // Compress the existing file down (saves generally about 80% of the file size)
      // we want to do as converting to webm can lead to a pThread error (memory out of bounds)
      // it also makes the webm conversion faster
      await new Promise((resolve, reject) => {
        // Heartbeat check if the user cancels
        const checkAbort = () => {
          if (abortController?.signal?.aborted) {
            ffmpeg.terminate() // Stop the ffmpeg process
            reject(new Error(`Cancelled upload for ${file.name}`))
          } else {
            setTimeout(checkAbort, 250)
          }
        }

        checkAbort()

        const settings = [
          '-i',
          `${fileTempName}.mp4`,
          // 1080p tends to break you can see it breaking on 1080p on this site https://clip.marco.zone/video/
          '-vf',
          'fps=30,scale=1280:720:flags=bilinear,format=yuv420p',
          '-c:v',
          'libx264',
          // Might need to update this preset depending on how clients
          '-preset:v',
          'slow',
          '-profile:v',
          'high',
          '-maxrate:v',
          '12561k',
          '-bufsize:v',
          '125610k',
          '-async',
          '1',
          '-af',
          'aformat=sample_rates=48000:channel_layouts=stereo',
          '-c:a',
          'libfdk_aac',
          '-b:a',
          '192k',
          '-f',
          'mp4',
          '-threads',
          '0',
          `${fileTempName}_compressed.mp4`,
        ]

        ffmpeg
          .exec([...settings])
          .then(resolve)
          .catch(reject)
      })

      // Update our stats so we can see how much file size we saved with compression
      const compressedMP4 = await ffmpeg.readFile(`${fileTempName}_compressed.mp4`)
      const compressedBlob = new Blob([compressedMP4], { type: 'video/mp4' })
      updateStats(file, { compressedSize: compressedBlob.size })
      file = new File([compressedBlob], `${fileTempName}_compressed.mp4`, { type: 'video/mp4' })
      URL.revokeObjectURL(previewFile)
      previewFile = `${URL.createObjectURL(file)}?type=${file.type}`

      step = `Optimizing ${truncateString(originalFile.name, 15)} for the Web`

      // Converting to webm files under 5MB tends to increase size so it's better to skip
      if (file.size <= MAX_VIDEO_FILE_SIZE) {
        updateStats(file)
        dispatch?.({ type: 'COMPLETE', file, stats })
        return { file, stats }
      } else {
        dispatch?.({
          type: 'CONVERSION',
          step,
          progress: 0,
          ffmpegRunning: true,
          thumbnail: previewFile,
        })
      }

      // Converting to WEBM + extra compression
      // generally gives us some extra space savings
      await new Promise((resolve, reject) => {
        // Heartbeat check if the user cancels
        const checkAbort = () => {
          if (abortController?.signal?.aborted) {
            ffmpeg.terminate() // Stop the ffmpeg process
            reject(new Error(`Cancelled upload for ${file.name}`))
          } else {
            setTimeout(checkAbort, 250)
          }
        }

        checkAbort()

        const settings = [
          '-i',
          `${fileTempName}_compressed.mp4`,
          '-c:v',
          'libvpx-vp9',
          '-crf',
          '30',
          '-b:v',
          '0',
          '-row-mt',
          '1',
          `${fileTempName}.webm`,
        ]

        ffmpeg
          .exec([...settings])
          .then(resolve)
          .catch(reject)
      })

      if (abortController?.signal?.aborted && ffmpeg.loaded) {
        dispatch?.({ type: 'ERROR', file, error: `Cancelled upload for ${file.name}` })
        return
      }

      dispatch?.({
        type: 'CONVERSION',
        step: 'Reading optimized file',
        progress: 0,
        ffmpegRunning: true,
      })

      const data = await ffmpeg.readFile(`${fileTempName}.webm`)
      const convertedBlob = new Blob([data], { type: 'video/webm' })
      // Use compressed if it's smaller
      file =
        convertedBlob.size <= compressedBlob.size
          ? new File([convertedBlob], 'converted_video.webm', { type: 'video/webm' })
          : file

      updateStats(file, {
        conversionSize: convertedBlob.size,
      })

      updateStats(file)
    }

    dispatch?.({ type: 'COMPLETE', file, stats })
    return { file, stats }
  } catch (error: unknown) {
    console.log(error)
    dispatch?.({ type: 'ERROR', error: (error as Error).message, file })
  }
}

export const useVideoCompression = () => {
  const [state, dispatch] = useReducer(reducer, { step: 'Idle', progress: 0 })

  const callback = useCallback(
    (file: File, abortController?: AbortController) =>
      optimizeFile(file, dispatch, abortController),
    []
  )

  return { state, optimizeFile: callback }
}

export const formatBytes = (bytes: number) => {
  const unitIndex = Math.floor(Math.log(bytes) / Math.log(1024))
  return `${(bytes / Math.pow(1024, unitIndex)).toFixed(2)}${units[unitIndex]}`
}

/**
 * Using HTML canPlayType to see if the browser supports this video type.
 * @param url Video URL
 * @returns If the browser can play this video
 */
export const canPlayVideo = (originalUrl: string | string[]) => {
  const updatedUrl = typeof originalUrl === 'string' ? [originalUrl] : originalUrl

  const canItPlay = updatedUrl.some((url) => {
    if (url === '') return
    if (url.includes('blob')) {
      // Add video type into search params
      return url.includes('video')
    }
    const dummyVideo = document.createElement('video')
    const fileExtension = url.match(/\.\w{3,4}($|\?)/gim)?.[0].replace(/\.|\?/gim, '')
    const canPlay = dummyVideo.canPlayType(`video/${fileExtension}`)
    dummyVideo.remove()
    return canPlay
  })

  return canItPlay
}
